import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import fbConfig from "./fbConfig";

// firebase.initializeApp(fbConfig);

if (!firebase.apps.length) {
  firebase.initializeApp(fbConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

firebase.firestore();

export default firebase;
