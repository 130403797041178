import { toast } from "react-toastify";
import { APP_LOADED } from "../../app/async/asyncReducer";
import firebase from "../../app/config/firebase";
import { SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";

export function signInUser(user) {
  return {
    type: SIGN_IN_USER,
    payload: user,
  };
}

export function signOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}

export function signOutFirebase() {
  return firebase.auth().signOut();
}

export function signInWithEmail({ email, password }) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function registerInFirebase({ email, password, displayName }) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await result.user.updateProfile({ displayName: displayName });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export function verifyAuth() {
  return (dispatch) => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(signInUser(user));
        dispatch({ type: APP_LOADED });
      } else {
        dispatch(signOutUser());
        dispatch({ type: APP_LOADED });
      }
    });
  };
}

export function setUserProfileData(user) {
  return firebase
    .firestore()
    .collection("users")
    .doc(user.uid)
    .set({
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL || null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export async function socialLogin(selectedProvider) {
  let provider;
  if (selectedProvider === "google") {
    provider = new firebase.auth.GoogleAuthProvider();
  }
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    console.log(result);
    if (result.additionalUserInfo.isNewUser) {
      await setUserProfileData(result.user);
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function updateUserPassword({ newPassword1 }) {
  const user = firebase.auth().currentUser;
  return user.updatePassword(newPassword1);
}
