import React from "react";
import { Item, Segment } from "semantic-ui-react";
import { format, fromUnixTime } from "date-fns";
import { NavLink } from "react-router-dom";

export default function WorkspaceListItem({ workspace }) {
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Image
              as={NavLink}
              to={`/workspaces/${workspace.id}`}
              size='tiny'
              circular
              src={workspace.logo || null}
            />
            <Item.Content>
              <Item.Header content={workspace.title} />
              <Item.Meta content={workspace.description} />
              <Item.Extra content={workspace.category} />
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        {workspace.createdAt?.seconds
          ? format(fromUnixTime(workspace.createdAt.seconds), "MMMM d, yyyy")
          : "loading ..."}
      </Segment>
    </Segment.Group>
  );
}
