import React from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";

export default function HomePage({ history }) {
  return (
    <Segment inverted textAlign='center' vertical className='masthead'>
      <Container>
        <Header as='h1' inverted>
          Code NameXX
        </Header>
        <Button onClick={() => history.push("/comms")} size='huge' inverted>
          Start Now
        </Button>
      </Container>
    </Segment>
  );
}
