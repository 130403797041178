import React from "react";
import SpaceListItem from "./SpaceListItem";

export default function SpaceList({ spaces }) {
  return (
    <>
      {spaces.map((space) => (
        <SpaceListItem space={space} key={space.id} />
      ))}
    </>
  );
}
