import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Segment, Sidebar } from "semantic-ui-react";

import HomePage from "../../features/home/HomePage";
import NavBar from "../../features/nav/NavBar";
import SideBar from "../../features/nav/SideBar";
import WorkspaceDashboard from "../../features/workspace/WorkspaceDashboard";

import ModalManager from "../common/modals/ModalManager";
import Sandbox from "../../features/sandbox/Sandbox";
import { ToastContainer } from "react-toastify";
import AccountPage from "../../features/auth/AccountPage";
import LoadingComponent from "./LoadingComponent";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import ProfilePage from "../../features/profiles/ProfilePage";
import CommDashboard from "../../features/comm/CommDashboard";
import UnauthPage from "../../features/auth/UnauthPage";
import WorkspaceDetailPage from "../../features/workspace/WorkspaceDetailPage";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  if (!isLoaded(auth && profile))
    return <LoadingComponent content='Loading App...' />;
  return children;
}

function PrivateRoute({ component, path }) {
  const auth = useSelector((state) => state.firebase.auth);

  return isLoaded(auth) && !isEmpty(auth) ? (
    <Route exact component={component} path={path} />
  ) : (
    <>
      <Redirect to='/unauth' />
    </>
  );
}

export default function App() {
  const [visible, setVisible] = useState(false);

  // const { initialized } = useSelector((state) => state.async);
  // if (!initialized) return <LoadingComponent content='Loading App...' />;

  return (
    <>
      <Router>
        <AuthIsLoaded>
          <ModalManager />
          <ToastContainer position='bottom-right' />
          <Route exact path='/' component={HomePage} />
          <Route exact path='/unauth' component={UnauthPage} />
          <Route
            path='/(.+)'
            render={() => (
              <>
                <Segment.Group>
                  <Segment>
                    <NavBar setVisible={setVisible} visible={visible} />
                  </Segment>
                  <Sidebar.Pushable className='patch' as={Segment}>
                    {/* <SideBar visible={visible} /> */}
                    <Sidebar.Pusher>
                      <Segment basic className='patch'>
                        <Switch>
                          <Route exact path='/sandbox' component={Sandbox} />
                          <PrivateRoute
                            path='/account'
                            component={AccountPage}
                          />
                          <PrivateRoute
                            path='/profile/:id'
                            component={ProfilePage}
                          />
                          <PrivateRoute
                            exact
                            path='/workspaces'
                            component={WorkspaceDashboard}
                          />
                          <PrivateRoute
                            exact
                            path='/workspaces/:id'
                            component={WorkspaceDetailPage}
                          />
                          <PrivateRoute
                            exact
                            path='/comms'
                            component={CommDashboard}
                          />
                          <Route
                            render={() => <Redirect to={{ pathname: "/" }} />}
                          />
                        </Switch>
                      </Segment>
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </Segment.Group>
              </>
            )}
          />
        </AuthIsLoaded>
      </Router>
    </>
  );
}
