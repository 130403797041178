import React from "react";
import { useSelector } from "react-redux";
import { Container, Grid } from "semantic-ui-react";
import WorkspaceForm from "./WorkspaceForm";
import WorkspaceList from "./WorkspaceList";
import WorkspaceListItemPlaceholder from "./WorkspaceListItemPlaceholder";
import {
  useFirestoreConnect,
  useFirebase,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";

export default function WorkspaceDashboard() {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  let workspacesQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    workspacesQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
      where: [
        ["createdBy", "==", firebase.auth().currentUser.uid],
        ["isCancelled", "==", false],
      ],
    };
  } else {
    workspacesQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
    };
  }

  useFirestoreConnect(() => [workspacesQuery]);

  const { workspaces } = useSelector((state) => state.firestore.ordered);

  return (
    <>
      <Container style={{ marginTop: 20, height: "100vmax" }}>
        <Grid stackable style={{ minHeight: "100%" }}>
          <Grid.Column width='10'>
            {isLoaded(workspaces) && !isEmpty(workspaces) ? (
              <WorkspaceList workspaces={workspaces} />
            ) : (
              <>
                <WorkspaceListItemPlaceholder />
                <WorkspaceListItemPlaceholder />
              </>
            )}
          </Grid.Column>
          <Grid.Column width='6'>
            <WorkspaceForm />
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}
