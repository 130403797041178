import React from "react";
import { Placeholder, Segment } from "semantic-ui-react";

export default function SpaceListItemPlaceholder() {
  return (
    <Placeholder fluid>
      <Segment.Group>
        <Segment>
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        </Segment>
        <Segment>
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>
      </Segment.Group>
    </Placeholder>
  );
}
