import React from "react";
import { Item, Segment } from "semantic-ui-react";
import { format, fromUnixTime } from "date-fns";
import { NavLink } from "react-router-dom";

export default function SpaceListItem({ space }) {
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Image
              as={NavLink}
              to={`/spaces/${space.id}`}
              size='tiny'
              circular
              src={space.logo || null}
            />
            <Item.Content>
              <Item.Header content={space.title} />
              <Item.Meta content={space.description} />
              <Item.Extra content={space.category} />
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        {space.createdAt?.seconds
          ? format(fromUnixTime(space.createdAt.seconds), "MMMM d, yyyy")
          : "loading ..."}
      </Segment>
    </Segment.Group>
  );
}
