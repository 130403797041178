import { format, fromUnixTime } from "date-fns";

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const serverLetter = "P:";
const serverName = "/ZAD_SRV_001";
const sharedName = "/sharedPipeline_001";

export function winToLinux(input) {
  return input
    .replace(/^\\\\\?\\/, "")
    .replace(/\\/g, "/")
    .replace(/\/\/+/g, "/")
    .replace(serverLetter, sharedName);
}
export function winToDpx(input) {
  return input
    .replace(/^\\\\\?\\/, "")
    .replace(/\\/g, "/")
    .replace(/\/\/+/g, "/")
    .replace(serverLetter, serverName);
}
export function linuxToWin(input) {
  return input.replace(sharedName, serverName);
}

export function filterPlainArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    return filterKeys.every((filter) => {
      // console.log("ff", filters[filter]);
      // const arrayKeys = Object.keys(item);
      // console.log("xx", item[filter]);
      let inputItem = item[filter];
      if (item[filter].seconds) {
        inputItem = format(fromUnixTime(item[filter].seconds), "yyyyMMdd");
      }
      // includes better than '=='
      return inputItem.toLowerCase().includes(filters[filter]);
    });
  });
}

export function filterDateArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    return filterKeys.every((filter) => {
      // console.log('ff',filter,filters[filter])
      // const arrayKeys = Object.keys(item);
      console.log("xxx", item[filter]);
      if (item[filter].seconds) {
        console.log("it is a date", item[filter].seconds);
      } else {
        console.log("it is not a date", item[filter]);
      }

      return (
        format(fromUnixTime(item[filter].seconds), "yyyyMMdd") ===
        filters[filter]
      );
    });
  });
}

export function getDaysAgo(days) {
  const todayDate = new Date();
  const previousDate = new Date();
  return new Date(
    previousDate.setTime(todayDate.getTime() - days * 24 * 3600000)
  );
}

export function createKeywords(name) {
  const arrName = [];
  let curName = "";
  name.split("").forEach((letter) => {
    curName += letter;
    arrName.push(curName);
  });
  return arrName;
}
