import React from "react";
import { Menu, Sidebar, Accordion, Icon } from "semantic-ui-react";

const clientList = (
  <div>
    <Menu.Item as='a'>
      <Icon name='object group outline' />
      Drawings
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='tasks' />
      Specs
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='th list' />
      RFI
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='table' />
      Submittals
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='camera' />
      Field Report
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='users' />
      Team
    </Menu.Item>
  </div>
);

const designList = (
  <div>
    <Menu.Item as='a'>
      <Icon name='object group outline' />
      Drawings
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='table' />
      Room Schedule
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='camera' />
      Progress Report
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='users' />
      Team
    </Menu.Item>
  </div>
);

const developmentList = (
  <div>
    <Menu.Item as='a'>
      <Icon name='object group outline' />
      Drawings
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='table' />
      Room Schedule
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='camera' />
      Progress Report
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='users' />
      Team
    </Menu.Item>
  </div>
);

const SubAccordionPanels = [
  {
    title: {
      content: "Client",
      icon: "user secret",
    },
    content: { content: clientList, key: "sa1-content" },
    key: "sub-accordion-1",
  },
  {
    title: {
      content: "Design",
      icon: "pencil alternate",
    },
    content: { content: designList, key: "sa2-content" },
    key: "sub-accordion-2",
  },
  {
    title: {
      content: "Development",
      icon: "cog",
    },
    content: { content: developmentList, key: "sa3-content" },
    key: "sub-accordion-3",
  },
  {
    title: {
      content: "Tender",
      icon: "file alternate outline",
    },
    content: { content: developmentList, key: "sa4-content" },
    key: "sub-accordion-4",
  },
  {
    title: {
      content: "Construction",
      icon: "industry",
    },
    content: { content: developmentList, key: "sa5-content" },
    key: "sub-accordion-5",
  },
  {
    title: {
      content: "Handover",
      icon: "handshake",
    },
    content: { content: developmentList, key: "sa6-content" },
    key: "sub-accordion-6",
  },
];

const AccordionExampleNested = () => (
  <Accordion inverted defaultActiveIndex={0} panels={SubAccordionPanels} />
);

export default function SideBar({ visible }) {
  return (
    <Sidebar
      className='patch'
      as={Menu}
      animation='slide along'
      direction='left'
      icon='labeled'
      inverted
      vertical
      visible={visible}
      width='thin'
    >
      <AccordionExampleNested />
    </Sidebar>
  );
}
