import React from "react";
import { useSelector } from "react-redux";
import { Container, Grid, Menu } from "semantic-ui-react";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";
import { NavLink } from "react-router-dom";

export default function NavBar({ setVisible, visible }) {
  const { authenticated } = useSelector((state) => state.auth);

  return (
    <>
      <Menu inverted borderless fixed='top'>
        <Container>
          <Menu.Item
            icon='bars'
            position='left'
            onClick={() => setVisible(!visible)}
          />
          <Menu.Item>
            <Grid>
              <Grid.Column only='large screen'></Grid.Column>
            </Grid>
          </Menu.Item>
          <Menu.Item content='CodeNameX Comm Beta' position='left' />
          {/* <Menu.Item as={NavLink} to='/workspaces' name='workspaces' /> */}
          <Menu.Item as={NavLink} to='/comms' name='communications' />
          {/* <Menu.Item as={NavLink} to='/sandbox' name='Sandbox' /> */}

          {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
        </Container>
      </Menu>
    </>
  );
}
