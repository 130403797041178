import { ADD_COMM } from "../../features/comm/commConstants";
import { ADD_SPACE } from "../../features/space/spaceConstants";
import { ADD_WORKSPACE } from "../../features/workspace/workspaceConstants";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function addWorkspace(value) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      logo: `https://picsum.photos/id/${getRandomInt(100, 200)}/100?grayscale`,
      // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
    };

    return firestore
      .collection("workspaces")
      .add(newValue)
      .then(() => {
        dispatch({ type: ADD_WORKSPACE, payload: newValue });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteWorkspace(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore.collection("workspaces").doc(valueId).delete();
  };
}

export function cancelWorkspace(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore
      .collection("workspaces")
      .doc(valueId)
      .update({ isCancelled: true });
  };
}

export function addSpace(value) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      logo: `https://picsum.photos/id/${getRandomInt(100, 200)}/100?grayscale`,
      // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
    };

    return firestore
      .collection("spaces")
      .add(newValue)
      .then(() => {
        dispatch({ type: ADD_SPACE, payload: newValue });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addComm(value) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
      // notesTrigram: trigram(value.notes.toLowerCase()),
    };

    return firestore
      .collection("comms")
      .add(newValue)
      .then(() => {
        dispatch({ type: ADD_COMM, payload: newValue });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cancelComm(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore.collection("comms").doc(valueId).update({ isCancelled: true });
  };
}
