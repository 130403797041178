import React from "react";
import WorkspaceListItem from "./WorkspaceListItem";

export default function WorkspaceList({ workspaces }) {
  return (
    <>
      {workspaces.map((workspace) => (
        <WorkspaceListItem workspace={workspace} key={workspace.id} />
      ))}
    </>
  );
}
