import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { decreament, increament } from "./testReducer";

export default function Sandbox() {
  const data = useSelector((state) => state.test.data);
  const [target, setTarget] = useState(null);
  const { loading } = useSelector((state) => state.async);
  const dispatch = useDispatch();

  return (
    <>
      <Container style={{ marginTop: 20, height: "100vmax" }}>
        <Button
          onClick={() =>
            dispatch(
              openModal({ modalType: "TestModal", modalProps: { data: 33 } })
            )
          }
          content='Open Modal'
          color='teal'
        />

        <h3> data is: {data} </h3>
        <Button
          loading={loading && target === "increment"}
          name='increment'
          content='increment'
          color='green'
          onClick={(e) => {
            setTarget(e.target.name);
            dispatch(increament(5));
          }}
        />
        <Button
          name='decrement'
          loading={loading && target === "decrement"}
          content='decrement'
          color='red'
          onClick={(e) => {
            setTarget(e.target.name);
            dispatch(decreament(10));
          }}
        />
      </Container>
    </>
  );
}
