import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import MyTextArea from "../../app/common/form/MyTextArea";
import MySelectInput from "../../app/common/form/MySelectInput";
import { categoryData } from "../../app/api/categoryOptions";
import MyDateInput from "../../app/common/form/MyDateInput";
import { addWorkspace } from "../../app/firestore/firestoreService";
import { useDispatch } from "react-redux";

const initialValues = {
  title: "",
  description: "",
  category: "",
  date: "",
  logo: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("you must provide a title"),
  description: Yup.string().required("you must provide a description"),
  category: Yup.string().required("you must select a category"),
  date: Yup.string().required("you must select a date"),
});

export default function WorkspaceForm() {
  const dispatch = useDispatch();
  return (
    <Segment clearing>
      <Header sub color='teal' content='Create Workspace' />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          dispatch(addWorkspace(values))
            .then(() => setSubmitting(false))
            .catch((error) => {
              setErrors({ submit: error.message });
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='title' placeholder='Workspace name' />
            <MyTextArea
              name='description'
              placeholder='Description...'
              rows={3}
            />
            <MySelectInput
              name='category'
              placeholder='stage'
              options={categoryData}
            />
            <MyDateInput
              name='date'
              placeholderText='enter date'
              dateFormat='MMMM d, yyyy'
            />
            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              positive
              content='Submit'
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
