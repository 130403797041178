import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import { Header } from "semantic-ui-react";
import SpaceDashboard from "../space/SpaceDashboard";

export default function WorkspaceDetailPage() {
  const { id } = useParams();
  const selectedWorkSpace = useSelector(
    (state) => state.firestore.ordered[`workspaces/${id}`]
  );

  const SelectedWorkspaceQuery = {
    collection: "workspaces",
    doc: id,
    storeAs: `workspaces/${id}`,
  };

  useFirestoreConnect(() => [SelectedWorkspaceQuery]);

  return (
    <>
      {isLoaded(selectedWorkSpace) && !isEmpty(selectedWorkSpace) ? (
        <>
          <Header content={`Workspace:  ${selectedWorkSpace[0].title}`} />
          <SpaceDashboard />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
