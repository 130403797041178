import React from "react";
import { useSelector } from "react-redux";
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestoreConnect,
} from "react-redux-firebase";
import { Container, Grid } from "semantic-ui-react";
import SpaceForm from "./SpaceForm";
import SpaceList from "./SpaceList";
import SpaceListItemPlaceholder from "./SpaceListItemPlaceholder";

export default function SpaceDashboard() {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  let spacesQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    spacesQuery = {
      collection: "spaces",
      orderBy: ["createdAt", "desc"],
      where: [
        ["createdBy", "==", firebase.auth().currentUser.uid],
        ["isCancelled", "==", false],
      ],
    };
  } else {
    spacesQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
    };
  }

  useFirestoreConnect(() => [spacesQuery]);

  const { spaces } = useSelector((state) => state.firestore.ordered);
  return (
    <>
      <Container style={{ marginTop: 20, height: "100vmax" }}>
        <Grid stackable style={{ minHeight: "100%" }}>
          <Grid.Column width='10'>
            {isLoaded(spaces) && !isEmpty(spaces) ? (
              <SpaceList spaces={spaces} />
            ) : (
              <>
                <SpaceListItemPlaceholder />
                <SpaceListItemPlaceholder />
              </>
            )}
          </Grid.Column>
          <Grid.Column width='6'>
            <SpaceForm />
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}
